import "./src/styles/tailwind.css"
import "vanilla-cookieconsent/dist/cookieconsent.css"

import React from "react"
import GlobalStateProvider from "./src/store/GlobalStateProvider"
import QueryClientProvider from "./src/components/layouts/QueryClientProvider"

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider>
    <GlobalStateProvider>{element}</GlobalStateProvider>
  </QueryClientProvider>
)
