import { createContext, Dispatch } from "react"
import { ICreateOrderResponse } from "~types/api/IOrder"
import { ICreateSearchResponse } from "~types/api/ISearch"
import { IGetUserResponse } from "~types/api/IUser"

export interface IContext {
  state: States | null
  dispatch: Dispatch<unknown>
}
export interface States {
  customerData: IGetUserResponse
  orderData: ICreateOrderResponse
  coupon: boolean
  search: ICreateSearchResponse
}

const Context = createContext<IContext>({
  state: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
})

export default Context
